$(function(){
  $(window).on('load',function(){
    var btnOffset = $('.fixed').offset().top;
    var windowHeight = $(window).height();
    var fixedBtn = $('.top-fixed');
    fixedBtn.hide();
    $(window).scroll(function(){
      if($(this).scrollTop() > btnOffset - windowHeight){
        fixedBtn.fadeIn();
      }else{
        fixedBtn.fadeOut();
      }
    });
  });
  
});
// $(function(){
//   var fixedBtn = $('.top-fixed');
//   fixedBtn.hide();
//   $(window).scroll(function(){
//     if($(this).scrollTop() > 100){
//       fixedBtn.fadeIn();
//     }else{
//       fixedBtn.fadeOut();
//     }
//   });
// });